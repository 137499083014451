import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import _ from 'lodash';
import { mainStore, userToolStore, livroStore, } from '@/utils/store-accessor';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Titulo',
                sortable: true,
                value: 'nome',
                align: 'left',
            },
            {
                text: 'Edição',
                sortable: true,
                value: 'edicao',
                align: 'left',
            },
            {
                text: 'Código',
                sortable: true,
                value: 'livro_codigo',
                align: 'left',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
        // @ts-ignore
        this.notFoundImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADDCAMAAACxkIT5AAAASFBMVEVUVFSoqKhOTk5RUVGrq6tzc3OZmZmFhYWwsLBMTEyXl5etra1vb29+fn6Li4uTk5NiYmJnZ2dbW1uhoaGAgIBeXl5ycnJsbGwxLNHVAAAEr0lEQVR4nO2cDZeiIBSG4V4ElQSVJv//P13ATPuY2Wlqzy7u+5wzk0l2uk9cQEOEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFAaTO+H/3ZQz8GTfj9TWRK4svLd2Ko0B+rtDhQcwAEcwAEcwAEcwAEcwAEcwMGuHahuiYLMXbly9OXBcLBDBwwHcCDgIJfDARyI/9tBwzPNIweXwj07kK1Z0PeF+lLo9+xAqoWnC/fj4BXgAA7gAA7gAA7gAA7gAA7gAA7gAA7gAA7g4P90kK6a2ZnPL6Dt10EMXgbvuuowDcMwHarO+SDt1yL25CDG77tepMn4K/GZ6DsfPezfgbLBDCn6RwdFE4MJn2nYiQNl/dQ8jP9yIDeTf2xhFw6UcuM3bkZhGt2jlmEPDqwf6fdHZmj09/eAlO/A6v7GwNwSnm9Vug2Pen1roXQHSlZXWRCjF0N1dL7VWrfeHavYUfD1Kyqp9uTAtuPm8zOJg8vDgXPa59GS1GbirSgeW7sfB7Zr1iKi0yfDgDRwOGwtNN1WQskOlFxbgtjmmy/HQVaaTd9B/SYfCnagtNjE5H97YhDHEBtnQq9vVKwD6y8BpR7vO6eTatuL0qWXLNaBdUtTwGS+cXJ4DleZS0I0zpbtQJlFAQ3hmUsKKgxLVVjmLhXrYAmEzLM3/1pzObZwB/PHZvFUJViqwnlQwXtwcNXFPSFBzvmwBwd0+OnFRXVKEnbggF5YB8BWtAcHryhIw2wq38FrCnJNKN0BTa8uiGGn0vvGj9d/a1neo1QHP+oT7yTIsh28ETiAAziAAziAAziAAziAAzjYiwOsIclTePtSoqGwtUSxpiwAAABwQ5p8lx7m31wpTzjj607u/DRv8byT55/b0/a6gx9OYPz34aH1KWrXsWDhggyG+aDbjB5yeNP85EPQSUvZDhSP0ulg8h2za0+clm2n+FZt683tDM9/Hz5YeWRBMXQxqlBNnQw8GGNCMMb1+SWVTeu/uJFM7aaDryvmU51KKDimEGR0cKobPtTHo9O1L01CdFApkR2QDrEm06hcfGhav8xbj6dVnO9gGOqqSasF2TGGm0ryUaGTsTacbHIQs6GZ1nkphRAdNNJQjIbGOp/rkJEpy1u/WQkqZTqTC/OcJdvRlYNOLQ7yzs7+lUh+TnJQ1SmaGNeY90zp8cqB9ZFDE+ZdMQOaKwckj7Q6EGN9KKtdjA4oBRUdVHMIscr3tw6cc8nBvEgYaX/joLKbeiBEXdq5c3SQPrw2NNUf8567enDOhbUeXOdC/DPT6uCjHspzIOI3HB2IOq8UR+6uPZgTPBbkXaOtaMh5E5MgO4i1oLo4aIwqS8HsgAebvlEvx/ht9/XxsQPua5P6BS/jE3tsOJqYsoMoMahzv0BdaamQan6OuI7jAxGU6VzdpuBj0t86iC1+rbtjsLGq87F2nUlTnEmm0dVkcz3wvs0Tkwqjd5z/p7acu1b7ah47d+u3OZjzBvVOazPO0/i8blPu8DFdOaNjdNinoVQlCqsFifNAKD/weiFsO+6/bG7Ll01e/+ebQP/8JwYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwB/gFh35K7dZjp9sAAAAASUVORK5CYII=';
        // public liberarLivroDialog = false;
        this.loading = false;
        this.livros = [];
        this.codigoLivro = '';
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Livros) {
            return userToolStore.userToolsIndexed.Livros[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Livros) {
            return userToolStore.userToolsIndexed.Livros[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Livros) {
            return userToolStore.userToolsIndexed.Livros[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Livros) {
            return userToolStore.userToolsIndexed.Livros[0].allow_delete;
        }
    }
    get palavrasTraduzidas() {
        return mainStore.palavrasTraduzidas;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    // public async onLiberarLivro() {
    //   // @ts-ignore
    //   if (this.userProfile.aluno_id) {
    //     await livroStore.createListening({
    //       livro_codigo: this.codigoLivro,
    //       // @ts-ignore
    //       aluno_id: this.userProfile.aluno_id,
    //     });
    //   }
    // }
    async onListening(item) {
        // @ts-ignore
        this.$router.push({
            name: 'main-livros-show',
            params: { livro_codigo: item.livro_codigo },
        });
    }
    async onGlossary(item) {
        // @ts-ignore
        this.$router.push({
            name: 'main-livros-glossary',
            params: { livro_codigo: item.livro_codigo },
        });
    }
    async mounted() {
        this.loading = true;
        // @ts-ignore
        if (this.userProfile.aluno_id) {
            this.livros = await livroStore.getListeningLivros(
            // @ts-ignore
            this.userProfile.aluno_id);
            this.livros[1].reverse();
            _.forEach(this.livros[0], (item1) => {
                _.forEach(this.livros[1], (item2) => {
                    if (item1.livro_codigo === item2.codigo) {
                        item1.capa = item2.capa;
                    }
                });
            });
            this.livros = this.livros[0];
        }
        this.loading = false;
    }
};
List = __decorate([
    Component
], List);
export default List;
